.case-container{
  margin-bottom: 150px;
}



.case-studies{
  margin-bottom: 350px;
}


.logo-images{
  background-color: transparent;
  background-image: none;
}



