
  .slick-dots li button:before {
    font-size: 12px;
  }
  
  /* Optional: Style the active dot differently */
  .slick-dots li.slick-active button:before {
    color: #4ade80 !important;  /* matches text-green-400 from your design */
    opacity: 1;
   
  }

  .slick-slide {
    padding: 0 0 0 0px !important; /* 👈 Reduce padding between items */
  }
  
  /* Add these styles to your CSS file */
.slick-dots {
    margin-top: 95px !important;
    position: relative !important;
    bottom: 40px !important;
  }
  
  .slick-dots li {
    margin: 0 8px !important;
  }
  
  .slick-dots li.slick-active div {
    background-color: rgb(2, 97, 26) !important;
  }
  
  .slick-track {
    display: flex !important;
    align-items: stretch;
  }
  
  .slick-slide {
    height: auto !important;
  }
  
  .slick-slide > div {
    height: 100%;
  }
  