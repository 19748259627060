* {
    font-family: "Montserrat", serif !important;

}

body {
    background: #101010;

}


html,
body {
    overflow-x: hidden;

}

.shadow-lg-g {
    --tw-gradient-from: none;
    background: #101010;
    --tw-gradient-to: none;
}

.banner-slide{
    background-size: 100% 102%;
}

.slick-track {
    gap: 25px;
}


.hover-d button svg{
    width: 60%;
    margin: 0 auto;
    height: 60%;
}

.icon-2 button{
    /* Estilos para el segundo elemento */
    width: 50px;
    height: 50px;
    bottom: 10vw;
  }

.hover-d button{
    background: linear-gradient(to right, #1E88E5, #4CAF50); /* Azul 500 a Verde 500 */
    border-radius: 8px; /* Opcional: para esquinas redondeadas */
    display: flex;
    justify-content: center;
    z-index: 50;
    align-items: center;
    color: white; /* Opcional: color del texto dentro del div */
    font-weight: bold;
    font-size: 1.2rem;
    border-radius: 100%;
}
.building-brand-section {

    background: linear-gradient(to bottom,
            rgba(16, 16, 16, 0) 0%,
            /* 5% superior transparente */
            rgba(16, 16, 16, 0) 15%,
            /* Mantiene la transparencia hasta 5% */
            rgba(16, 16, 16, 1) 30%,
            /* Degradado progresivo hasta #101010 */
            rgba(16, 16, 16, 1) 100%);
    /* El resto sólido en #101010 */

}

.g-icons{
    position: fixed;
    right: 20px;
    bottom:100px;
}

.fa-arrow-right{
    transform: rotate(-45deg);
}

.mb-menu {

    top: 25vw;
}

.nav-s {
    border: 1px solid gray;
    background: #3c3c3c;
}



.nav-sa {
    background-color: rgb(52, 139, 74);
    border: 1px solid gray;
}

.nav-s:hover {
    background-color: rgb(2, 97, 26);
}



.MuiButtonBase-root svg {
    width: 1.5em;
    height: 1.5em;
}


.enm {
    position: absolute;
    top: -20%;
    pointer-events: none;
    left: 0;
    width: 500px;
    height: 500px;
    background: url("../public/Ellipse.png");
    background-size: cover;
}

.galaxy-section .md:block {
    background: #101010;
    ;
}

.wb-car .carousel-item{
    filter: grayscale(100%) brightness(150%);
  

}

.wb-car .carousel-item img{
    width: auto;
    height: -webkit-fill-available;
}

.mb-subt li div p {
    font-size: 1rem;
    font-weight: 500;
}

.g-m-i+.g-m-i {
    border-top: 1px solid rgb(190, 190, 190);
}

.galaxy-sing-in {
    --tw-gradient-from: #5640ff2a var(--tw-gradient-from-position);
    --tw-gradient-to: #00fe8c1f var(--tw-gradient-to-position);

}

.galaxy-slide {
    background-image: linear-gradient(to top, #101010 5%, transparent 100%)
}

.galaxy-slide h1 {
    font-size: 3rem;
    ;
}

.galaxy-slide button {
    z-index: 5;

}

.galaxy-slide button span {
    font-size: 1rem;
}

.galaxy-slide button .bg-white {
    position: relative;
    right: -20px;
    ;
}

.galaxy-sing-in h2 {
    font-size: 25px;
    font-weight: 600;
}

.galaxy-sing-in p {
    font-size: 16px;
    font-weight: 200;
}

.element-shadow {
    box-shadow: inset 0px 0px 10px rgba(0, 0, 0, .4);
}

.social-button {
    border: 1px solid white;
}

.social-button:hover span {
    color: #ffffff;
}

/* Mobile view  */



@media (max-width: 767px){

    .banner-slide{
        background-size: cover;

        
    }

    .cases-card.c1 .grid-item{
        width: 60vw;
        height: 80vw;
    }

    .cases-card.c2 .grid-item{
        width: 40vw;
        height: 60vw;
    }

    .cases-card.c3 .grid-item{
        width: 93vw;
        height: 50vw;
    }

    .cases-card.c4 .grid-item{
        width: 67vw;
        height: 70vw;
    }

    .cases-section{
        height: 220vw;
    }

    
    .cases-cards{
        position: relative;
        top: 10vw;
        height: 120vw;
        width: 100%;
        overflow-x: scroll;
    }

    .scc {
        height: 150vw;
    }

    .partner-video{
        width: 100vw;
       left:-8vw;
    }

    .partner-section p {
            width: 95vw;  
            margin-bottom: 5px;  
            margin-left: -16px;
            margin-right: 0px;

    }

    

    .whywork-section .grid.gap-8{
        overflow-x: scroll;
    }


    .carousel-track{
        margin-top: 100px;
        margin-bottom: -20px;
    }


    .whywork-section{
        top: 15vh;
    }


    .text-mob{
        margin-top: -100px;
    }

    .rs-mob{
        margin-left: 30px;
    }


    .mobile-pagination{
        margin-left: 8vw;
    }

    .case-container{
        margin-top: 100px;
    }

    .case-studies-comp{
        height: 70vh;
    }

}







@media (max-width: 1200px) { /* phone */
    .md\:hidden {
        display: block !important;
    }

    .md\:block {
        display: none !important;
    }

    .galaxy-banner{
        height: 100vh;
    }



    .building-brand-section{
        position: relative;
        top: -20vh;
    }

    .galaxy-banner .content-banner{
        height: 100%;
    }

    .galaxy-banner .items-start {
        width: 90%;
        ;
    }

    .galaxy-banner .items-start h1{
     font-size: 2.5rem;
    }

    .galaxy-banner .items-start p{
        font-size: 1rem;
        margin-bottom: 5vw;
       }

 

    .wb-car{
        position: relative;
        top: 8rem;
    }


    

    .cases-section .text-center.py-10 span{
        font-size: 2.2rem;
    }

    .cases-section .text-center .p-4{
        font-size: 1.5rem;;
        width: 100%;
        margin: 3vw;
        text-align: left;
    }

    .whywork-section .text-center{
        width: 100%;
        padding: 0 5%;
        height: auto;
        margin: 10vw 0;
    }
    .whywork-section h2{
        font-size: 2.5rem;
    }

    .whywork-section .grid.gap-8{
      display: flex;
      overflow-x: scroll;
        position: relative;
        top: -20vw;
        
    }

    .whywork-section .grid.gap-8 .why-op{
        padding: 0;
    }

    .whywork-section .grid p{
      width: 90vw;
    }

    .partner-section{
        position: relative;
        top: -10vh;
    }

    .partner-section .text-start{
        position: relative;
        top: 8vh;
        height: 50vh;
    }

    .solutions-section{
        position: relative;
        top: -35vw;
    }

    .testimonials-section .text-mob{
        display: block;
    }

    .pag-ga-cont {
        position: absolute;
        bottom: -12%;
        left: 88%;
        right: 0 !important;
        width: 100px;
        transform: translateX(10%) rotate(-90deg);
    }

    .nav-s.shadow-lg:hover{
        background-color: #757575;
    }

    .nav-s.w-3:hover{
        background-color: #16580d;
    }

}



@media (min-width: 1201px) {  /*desktop*/

    .testimonials-section{
        position: relative;
        top: -40vh;
    }

    .partner-section{
        position: relative;
        top: -18vh;
        padding-bottom: 5vh;
    }

    .solutions-section {
        position: relative;
        top: -20vh;
    }

    .partner-section .relative.mt-5{
     height: 70vh;   
    }

 

    .footer-g-d{
        margin-top: -70vh;
    }

    .galaxy-banner{
       height: 100vh !important;
    }


    .md\:hidden {
        display: none !important;
    }

    .md\:block {
        display: block !important;
    }

    .galaxy-logo {
        margin: 0;
    }

    .galaxy-header-content {
        padding: 0 2%;
        margin-top: 1%
    }

    .building-brand-section {
   position: relative;
   bottom: 14vh;
   
    }



    .text-lg {
        font-size: 16px;
        font-weight: regular;
    }

    .galaxy-menu a:hover {
        border-bottom: 1px solid #00fe8a;
        color: #ffffff;
    }

    .galaxy-solutions-submenu {
        width: 93vw;
        position: fixed;
        top: 80px;
        left: 48.5vw;
        border-radius: 20px;
        z-index: 5;
    }



    .galaxy-solutions-submenu .items-start {
        width: fit-content;
        font-size: 3rem;
        
    }

    .content-banner{
        height: 100vh;
    }

    .banner-slide{
        height: 100vh;;
    }

    .galaxy-banner .items-start h1 {
        font-size: 4rem;
        width: 70%;
    }

    .galaxy-banner .items-start p {
        font-size: 1.25rem;
        ;
        width: 70%;
    }

    .galaxy-solutions-submenu li {
        list-style-type: none;
        font-size: 16px;
        margin:0;
       
    }

    .partner-section p{
        padding: 1rem 0 !important;
    }

    .partner-section .font-bold{
        font-weight: 500;
        font-size: 2.5rem;
    }

    .list-disc{
        margin-top: 10px !important;
        font-size: 14px !important;
        color: rgb(51, 51, 51);
        font-weight: 100;
    }

    .galaxy-solutions-submenu h3 {
        font-size: 18px;
        font-weight: 600;
    }

    .galaxy-solutions-submenu p {
        font-size: 16px;
        font-weight: lighter;
    }

    .book-a-demo:hover {
        border: none !important;
        --tw-gradient-to: #02FA8D var(--tw-gradient-to-position) !important;
        --tw-gradient-from: #5444FD var(--tw-gradient-from-position) !important;
    }

    .book-a-demo:hover .rocket-icon {

        --tw-gradient-to: #5444FD var(--tw-gradient-to-position);
        background-clip: text;
    }

    .pagination-g.bg-white {
        background-color: white;
        width: 6rem;
        height: .5rem;
        ;

    }

    .bg-gray-400.pagination-g {
        background-color: white;
        width: 3rem;

    }

    .pagination-g {
        margin-bottom: 20px !important;
    }


}

.close-video {
    background: white;
    color: black;
    width: 65px;
    height: 65px;
    border-radius: 100%;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, .4);
}



.solutions-section .text-lg {
    color: white;
}


.testimonial-arrow {
    font-size: 2rem;
    ;
}

.bg-gray-700:hover{
    background-color: rgb(112, 112, 112) !important;
}

.text-fixed-icons {
    background-color: #707070;
}

.wb-car {

    max-width: 80rem;
}





.text-centered {
    margin: 0 auto;
}




/* Tablet styling */

@media (min-width: 768px) and (max-width: 1200px) {  /* tablet */

    .galaxy-banner {
        height: 50vh;


        
    }


    .partner-video{
        width: auto !important;
        position: relative;
        left: 0 !important;
    }
   

    .g-why-icon img,     .whywork-section .grid h3 {
        left: 0 !important;
    }

    .g-icons{
    
        right: 32px !important;
        bottom:100px;
    }

   
    .wb-car .carousel-item {
        width: auto;
        height: none;

    }

    .wb-car {
    top: 0;
    }

    .galaxy-banner p{
        font-size: 1rem;
        margin-bottom: 20px;
    }

    .wb-car .carousel-item img {
        width: 100%;

    }

   

    .footer-g-m{
    background-image: linear-gradient(rgb(16, 16, 16) 5%, transparent 30%), url(/public/footer2.png) !important;
    background-size: cover;
    background-position: 50% 30%;
    }

    .psec-t{
        background: radial-gradient(circle at 46% 20vh, rgba(89, 59, 199, 0.53),rgba(0, 255, 128, 0.11), transparent 20%) !important;
    }
    .whywork-section .grid.gap-8{
        display: flex;
        position: relative;
        top: -20vw;
    }

    .whywork-section .why-op{
        width: 90vw;
        text-align: center;
    }

    .whywork-section .why-op p{
        width: 87vw;
    }

    .building-brand-section{
        width: 63%;
        margin: 0 auto;
    }

    .building-brand-section .bbcard .relative{
        background: black;
    }

    .img-deg{
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: 78%; /* 60% negro + 10% degradado */
        background: linear-gradient(to right, 
          black 60%, /* Negro hasta el 60% */
          rgba(0, 0, 0, 0) 100%, /* Transparente al 70% (10% de degradado) */
          transparent 70% /* El resto transparente */
        );
        pointer-events: none;
    }

    .building-brand-section .bbcard img{
        float: right !important;
    width: auto;
    position: static;


    }




    .galaxy-banner .items-start {
        width: 60%;
        height: 100%;
    }

 

    .why-op {
        padding: 2rem .5rem;
    }

    .galaxy-banner h1 {
        font-size: 2.5rem;
        ;
    }


    .rs-mob a {
        margin-right: .8rem !important;
        margin-left: 0 !important;
    }


    .partner-section p {
        padding: 0;
    }

    .testimonials-mob {
        display: block;
    }

    .pag-ga-cont {
        position: absolute;
        bottom: -40%;
        left: 107%;
        right: 0 !important;
        width: 100px;
        transform: translateX(10%) rotate(-90deg);
    }



    .cases-section {
        position: relative;
        top: 0rem;
        background-color: #101010;
    }

    .cases-section .text-center span {
        font-size: 2rem;
    }

    .cases-section .mx-auto{
        text-align: left;
        padding: 2% 6%;
    }

    .right-10 {
        right: 1rem;
        ;
    }

    .cases-section .font-medium {
        font-size: 2.5rem;
    }

    .cases-section .font-normal {
        font-size: 1rem;
        width: auto;
        margin-bottom: 2rem;
    }

    .cases-section .bg-contain {
        overflow-x: scroll;
        justify-content: center;
        height: 50vw;
        width: 100%;
    }

    .whywork-section {
        position: relative;
        top: 5rem;
    }

    .solutions-section {
        position: relative;
        top: -5rem;
    }

    .solutions-section .font-medium {
        font-size: 2.5rem;
    }

    .whywork-section h2 {
        font-size: 2rem;
    }

    .partner-section h2 {
        font-weight: 500;
        width: 80%;
        font-size: 2rem;
    }

    .whywork-section p {
        font-size: 1rem;
        color: white;
       
    }



    .text-mob {
        display: block;
    }

    .arrow-testi {
        font-size: 2.5rem;
    }

    .partner-video {
        width: 101vw;
        position: relative;
        left: -9vw;
    }

    .partner-section p {
        width: 80%;
    }

    .whywork-section .container .g-80 {
        width: 80%;
        ;
    }

    .whywork-section .container .g-80 .text-center {
        width: 100%;
    }

    .whywork-section .grid {
        display: flex;
        height: auto;
        overflow-x: scroll;
        width: 100%;
    }



    .whywork-section .grid h3 {
        font-size: 2rem;
        width: 90vw;
        margin: 0 auto;
        position: relative;
        left: 3%;
    }

    .g-why-icon {
        display: block;
        width: 90vw;
        align-items: center;
    }

    .g-why-icon img {
        margin: 0 auto;
        position: relative;
        left: 4%;
    }

    .partner-section .w-full {
        margin: 0;
    }

    .partner-section .g-sep {

        padding-top: 0;

    }


    .partner-section p {
        color: white;
        ;
    }

    .g-f-m {
        background-image: linear-gradient(to bottom,
                #101010 15%,
                transparent 100%);
        height: 8rem;
        border-top: solid 5px #101010;
    }

    .banner-video {
        width: 80%;
        height: 30%;
    }


}



@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .whywork-section .grid {
        display: grid;
        overflow-x: hidden;
        overflow-y: hidden;
    }

    .g-why-icon {
        width: -webkit-fill-available;
    }

    .whywork-section h3 {
        width: 100% !important;
        font-size: 1.5rem !important;
    }

    .whywork-section p {
        width: auto;
    }    
}