/* Mobile-first base styles */
.quiz-wrapper {
  background-image: url('background_quiz.png');
  background-size: cover;
  background-position: center;
  min-height: 100vh;
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.quiz-container {
  width: 100%;
  max-width: 800px; /* Fixed width for mobile */
  padding: 15px;
}

/* Step Header & Progress Bar */
.step-header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto 15px; /* Increased bottom margin */
  color: white;
  width: 100%;
}

.step-header {
  font-size: 1rem;
  font-weight: bold;
  text-align: left; /* Changed to left align */
  flex-grow: 1;
}

.step-pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.9rem;
  margin-left: 10px;
  white-space: nowrap; /* Prevent wrapping */
}

.progress-container {
  width: 100%;
  margin: 0 auto 20px; /* Increased bottom margin */
}

.progress-bar {
  width: 100%;
  background-color: transparent;
  border-radius: 5px;
  border: 1px solid rgb(207, 199, 199);
  height: 7px;
  overflow: hidden;
  margin: 5px 0 20px 0 ;
}

.progress {
  height: 100%;
  background: linear-gradient(90deg, #00ff89, #5541ff);
  border-radius: 5px;
  transition: width 0.3s ease;
}

/* Email Input Styling */
.email-input {
  margin-top: 15px;
}

.email-input input {
  width: 100%;
  padding: 12px 15px;
  border: 1px solid #ccc;
  border-radius: 8px;
  font-size: 1rem;
  color: #333;
  background-color: white;
}

.email-input input::placeholder {
  color: #999;
}

/* Start Page */
.start-page {
  position: relative;
  text-align: center;
  background-color: #082a46;
  padding: 20px; /* Adjust padding as needed */
  border-radius: 20px; /* Ensures rounded corners */
  z-index: 1;
  overflow: hidden;
}

.start-page::before {
  content: "";
  position: absolute;
  inset: 0;
  padding: 2px; /* Border thickness */
  border-radius: 20px; /* Ensures the border is rounded */
  background: linear-gradient(90deg, #00ff89, #5541ff); /* Gradient border */
  -webkit-mask: linear-gradient(white, white) content-box, linear-gradient(white, white);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  z-index: -1;
}

.start-page h1 {
  font-size: 1.5rem;
  color: white;
  margin-bottom: 15px;
}

.start-page p {
  font-size: 1rem;
  color: white;
  margin-bottom: 25px;
}

.start-button {
  padding: 12px 25px;
  background: linear-gradient(90deg, #00ff89, #5541ff);
  color: white;
  border: none;
  border-radius: 40px;
  font-size: 1rem;
}

/* Quiz Content */
.content-box {
  background-image: url('quiz_dialog.png');
  background-size: cover;
  border-radius: 20px;
  padding: 25px; /* Increased padding */
  margin: 0 auto;
  width: 100%;
}

.question-text {
  font-size: 1.1rem;
  color: white;
  text-align: center;
  margin-bottom: 20px;
}

/* Vertical Options */
.options {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.option {
  display: flex;
  align-items: center;
  background: white;
  padding: 12px 15px;
  border-radius: 8px;
  gap: 12px;
}

.option-text {
  color: black;
  font-size: 0.9rem;
}

/* Circular Checkboxes */
.option input[type="checkbox"] {
  -webkit-appearance: none;
  appearance: none;
  width: 16px;
  height: 16px;
  border: 2px solid #ccc;
  border-radius: 50%;
  position: relative;
  flex-shrink: 0;
}

.option input[type="checkbox"]:checked {
  background-color: #2196F3;
  border-color: #2196F3;
}

.option input[type="checkbox"]:checked::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 8px;
  height: 8px;
  background: white;
  border-radius: 50%;
}

/* Navigation */
.navigation {
  display: flex;
  justify-content: space-between;
  margin-top: 25px; /* Increased top margin */
  gap: 8px;
}

.next-button,
.finish-button,
.back-button {
  padding: 10px 15px; /* Slightly larger padding */
  border-radius: 40px;
  font-size: 0.9rem; /* Slightly larger font */
  min-width: 90px; /* Slightly wider */
}

.next-button,
.finish-button {
  background: linear-gradient(90deg, #00ff89, #5541ff);
  color: white;
  border: none;
}

.back-button {
  background: transparent;
  border: 2px solid white;
  color: white;
}

/* Thank You Page */
.quiz-thank-you-screen {
  background-image: url('Recurso 1.png');
  background-size: cover;
  padding: 60px;
  border-radius: 20px;
  text-align: center;
  width: 100%;
  margin: 0 auto;
}

.quiz-thank-you-screen h2 {
  font-size: 1.2rem;
  color: white;
  margin-bottom: 15px;
}

.quiz-thank-you-screen p {
  font-size: 1rem;
  color: white;
  margin: 15px 0;
}

.thank-you-buttons {
  display: flex;
  flex-direction: column;
  gap: 12px; /* Slightly larger gap */
  margin-top: 25px;
}

.get-started-btn,
.explore-services-btn {
  padding: 12px; /* Slightly taller */
  border-radius: 40px;
  font-size: 0.9rem;
}

.get-started-btn {
  background: linear-gradient(90deg, #00ff89, #5541ff);
  color: white;
  border: none;
}

.explore-services-btn {
  position: relative;
  background: transparent;
  color: white; /* Adjust text color */
  font-size: 16px;
  cursor: pointer;
  border: none; /* Remove default border */
  border-radius: 40px;
  z-index: 1;
  overflow: hidden;
}

.explore-services-btn::before {
  content: "";
  position: absolute;
  inset: 0;
  padding: 2.5px; /* Border thickness */
  border-radius: 20px; /* Ensure rounded corners */
  background: linear-gradient(90deg, #00ff89, #5541ff); /* Gradient border */
  -webkit-mask: linear-gradient(white, white) content-box, linear-gradient(white, white);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  z-index: -1;
}

.thank-you-art img {
  max-width: 100%;
  height: auto;
  margin-top: 25px; /* Increased top margin */
}

/* Tablet/Desktop adjustments */
@media (min-width: 768px) {
  .quiz-container {
    max-width: 800px;
    padding: 25px; /* Increased padding */
  }

  .step-header {
    font-size: 1rem;
  }

  .step-pagination {
    font-size: 1rem;
  }

  .question-text {
    font-size: 1.2rem;
  }

  .option-text {
    font-size: 0.9rem;
  }

  .thank-you-buttons {
    flex-direction: row;
    justify-content: center;
    gap: 20px; /* Larger gap on desktop */
  }

  .get-started-btn,
  .explore-services-btn {
    padding: 12px 30px; /* Wider buttons */
    font-size: 1rem;
  }

  .next-button,
  .finish-button,
  .back-button {
    padding: 12px 25px; /* Larger buttons */
    font-size: 1rem;
    min-width: 110px; /* Wider minimum width */
  }

  .email-input input {
    padding: 15px 20px; /* Larger input on desktop */
  }
}

@media (max-width: 767px) {
  .quiz-wrapper {
    max-width: 500px;
    padding: 10px;
  }

  .step-header-container {
    flex-direction: row;
    align-items: center;
    margin-bottom: 12px;
  }

  .step-header {
    font-size: 0.7rem;
    padding-right: 2px;
  }

  .step-pagination {
    font-size: 0.7rem;
  }

  .progress-container {
    margin-bottom: 15px;
  }

  .content-box {
    padding: 20px 15px;
  }

  .question-text {
    font-size: 0.9rem;
    margin-bottom: 15px;
  }

  .option {
    padding: 10px 12px;
    gap: 10px;
  }

  .option-text {
    font-size: 0.6rem;
  }

  .option input[type="checkbox"] {
    width: 14px;
    height: 14px;
  }

  .email-input input {
    padding: 10px 12px;
    font-size: 0.9rem;
  }

  .navigation {
    margin-top: 20px;
    gap: 8px;
  }

  .next-button,
  .finish-button,
  .back-button {
    padding: 8px 12px;
    font-size: 0.8rem;
    min-width: 80px;
  }

  /* Start Page Mobile */
  .start-page {
    padding: 15px;
  }

  .start-page h1 {
    font-size: 1.3rem;
    margin-bottom: 12px;
  }

  .start-page p {
    font-size: 0.9rem;
    margin-bottom: 20px;
  }

  .start-button {
    padding: 10px 20px;
    font-size: 0.9rem;
  }

  /* Thank You Page Mobile */
  .quiz-thank-you-screen {
    padding: 20px 15px;
  }

  .quiz-thank-you-screen h2 {
    font-size: 1.3rem;
  }

  .quiz-thank-you-screen p {
    font-size: 0.7rem;
  }

  .thank-you-buttons {
    margin-top: 20px;
    gap: 10px;
  }

  .get-started-btn,
  .explore-services-btn {
    padding: 10px;
    font-size: 0.85rem;
  }
}